import { css } from 'styled-components';
import { colorsV2 } from '../../colors-v2';

const colorRed = css`
  &.color-red {
    &.color-style-bold {
      color: ${colorsV2.red100};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.red5};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.red10};
      }

      &.disabled {
        background-color: transparent;
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }

    &.color-style-matte {
      color: ${colorsV2.red100};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.red20};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.red40};
      }

      &.disabled {
        background-color: transparent;
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }
  }
`;

const colorGreen = css`
  &.color-green {
    &.color-style-bold {
      color: ${colorsV2.green100};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.green5};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.green10};
      }

      &.disabled {
        background-color: transparent;
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }

    &.color-style-matte {
      color: ${colorsV2.green100};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.green20};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.green40};
      }

      &.disabled {
        background-color: transparent;
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }
  }
`;

const colorLight = css`
  &.color-light {
    &.color-style-bold {
      color: ${colorsV2.gray140};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.gray5};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.gray10};
      }

      &.disabled {
        background-color: transparent;
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }

    &.color-style-matte {
      color: ${colorsV2.gray140};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.gray10};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.gray20};
      }

      &.disabled {
        background-color: transparent;
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }
  }
`;

const colorNeutral = css`
  &.color-neutral {
    &.color-style-bold {
      color: ${colorsV2.gray180};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.gray120};
        color: ${colorsV2.white100};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.gray140};
        color: ${colorsV2.white100};
      }

      &.disabled {
        background-color: transparent;
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }

    &.color-style-matte {
      color: ${colorsV2.gray180};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.gray20};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.gray40};
      }

      &.disabled {
        background-color: transparent;
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }
  }
`;

const colorDark = css`
  &.color-dark {
    &.color-style-bold {
      color: ${colorsV2.gray180};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.gray5};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.gray10};
      }

      &.disabled {
        background-color: transparent;
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }

    &.color-style-matte {
      color: ${colorsV2.black100};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.gray10};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.gray20};
      }

      &.disabled {
        background-color: transparent;
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }
  }
`;

const colorBlue = css`
  &.color-blue {
    &.color-style-bold {
      color: ${colorsV2.blue100};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.blue5};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.blue10};
      }

      &.disabled {
        background-color: transparent;
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }

    &.color-style-matte {
      color: ${colorsV2.blue100};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.blue20};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.blue40};
      }

      &.disabled {
        background-color: transparent;
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }
  }
`;

const colorPrimary = css`
  &.color-primary {
    &.color-style-bold {
      color: ${colorsV2.primary100};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.primary5};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.primary10};
      }

      &.disabled {
        background-color: transparent;
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }

    &.color-style-matte {
      color: ${colorsV2.primary100};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.primary20};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.primary40};
      }

      &.disabled {
        background-color: transparent;
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }
  }
`;
const colorPurple = css`
  &.color-purple {
    &.color-style-bold {
      color: ${colorsV2.purple100};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.purple5};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.purple10};
      }

      &.disabled {
        background-color: transparent;
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }

    &.color-style-matte {
      color: ${colorsV2.purple100};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.purple20};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.purple40};
      }

      &.disabled {
        background-color: transparent;
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }
  }
`;
export const filledTransparentStyles = css`
  &.filled.transparent {
    background-color: transparent;

    ${colorPrimary}
    ${colorBlue}
    ${colorDark}
    ${colorNeutral}
    ${colorLight}
    ${colorGreen}
    ${colorRed}
    ${colorPurple}
  }
`;
