import { css } from 'styled-components';
import { colorsV2 } from '../../colors-v2';

const colorRed = css`
  &.color-red {
    &.color-style-bold {
      background-color: ${colorsV2.red100};
      color: ${colorsV2.white100};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.red120};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.red140};
      }

      &.disabled {
        background-color: ${colorsV2.gray10};
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }

    &.color-style-matte {
      background-color: ${colorsV2.red10};
      color: ${colorsV2.red100};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.red20};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.red40};
      }

      &.disabled {
        background-color: ${colorsV2.gray10};
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }
  }
`;

const colorGreen = css`
  &.color-green {
    &.color-style-bold {
      background-color: ${colorsV2.green100};
      color: ${colorsV2.white100};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.green120};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.green140};
      }

      &.disabled {
        background-color: ${colorsV2.gray10};
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }

    &.color-style-matte {
      background-color: ${colorsV2.green10};
      color: ${colorsV2.green100};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.green20};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.green40};
      }

      &.disabled {
        background-color: ${colorsV2.gray10};
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }
  }
`;

const colorLight = css`
  &.color-light {
    &.color-style-bold {
      background-color: ${colorsV2.white100};
      color: ${colorsV2.gray140};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.gray5};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.gray10};
      }

      &.disabled {
        background-color: ${colorsV2.white100};
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }

    &.color-style-matte {
      background-color: ${colorsV2.gray10};
      color: ${colorsV2.gray140};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.gray20};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.gray40};
      }

      &.disabled {
        background-color: ${colorsV2.gray5};
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }
  }
`;

const colorNeutral = css`
  &.color-neutral {
    &.color-style-bold {
      background-color: ${colorsV2.gray100};
      color: ${colorsV2.white100};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.gray120};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.gray140};
      }

      &.disabled {
        background-color: ${colorsV2.gray10};
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }

    &.color-style-matte {
      background-color: ${colorsV2.gray10};
      color: ${colorsV2.gray140};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.gray20};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.gray40};
      }

      &.disabled {
        background-color: ${colorsV2.gray10};
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }
  }
`;

const colorDark = css`
  &.color-dark {
    &.color-style-bold {
      background-color: ${colorsV2.gray140};
      color: ${colorsV2.white100};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.gray100};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.gray100};
      }

      &.disabled {
        background-color: ${colorsV2.gray10};
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }

    &.color-style-matte {
      background-color: ${colorsV2.gray10};
      color: ${colorsV2.black100};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.gray20};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.gray40};
      }

      &.disabled {
        background-color: ${colorsV2.gray10};
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }
  }
`;

const colorBlue = css`
  &.color-blue {
    &.color-style-bold {
      background-color: ${colorsV2.blue100};
      color: ${colorsV2.white100};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.blue120};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.blue140};
      }

      &.disabled {
        background-color: ${colorsV2.gray10};
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }

    &.color-style-matte {
      background-color: ${colorsV2.blue10};
      color: ${colorsV2.blue100};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.blue20};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.blue40};
      }

      &.disabled {
        background-color: ${colorsV2.gray10};
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }
  }
`;

const colorPrimary = css`
  &.color-primary {
    &.color-style-bold {
      background-color: ${colorsV2.primary100};
      color: ${colorsV2.white100};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.primary120};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.primary140};
      }

      &.disabled {
        background-color: ${colorsV2.gray10};
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }

    &.color-style-matte {
      background-color: ${colorsV2.primary10};
      color: ${colorsV2.primary100};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.primary20};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.primary40};
      }

      &.disabled {
        background-color: ${colorsV2.gray10};
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }
  }
`;

const colorPurple = css`
  &.color-purple {
    &.color-style-bold {
      background-color: ${colorsV2.purple100};
      color: ${colorsV2.white100};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.purple120};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.purple140};
      }

      &.disabled {
        background-color: ${colorsV2.gray10};
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }

    &.color-style-matte {
      background-color: ${colorsV2.purple10};
      color: ${colorsV2.purple100};

      &:hover:not(.loading):not(.disabled) {
        background-color: ${colorsV2.purple20};
      }

      &:active:not(.loading):not(.disabled) {
        background-color: ${colorsV2.purple40};
      }

      &.disabled {
        background-color: ${colorsV2.gray10};
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }
  }
`;

export const filledStyles = css`
  &.filled:not(.transparent) {
    ${colorPrimary}
    ${colorBlue}
    ${colorDark}
    ${colorNeutral}
    ${colorLight}
    ${colorGreen}
    ${colorRed}
    ${colorPurple}
  }
`;
