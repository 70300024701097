import * as React from 'react';
import styled from 'styled-components';

import { flexGap } from '@doltech/utils/lib/css-style';
import { SpokeLoading } from '@doltech/core/lib/auth/SpokeLoading';

const Icon = styled.span`
  font-size: 20px;
  line-height: 0;
`;
const Badge = styled.div``;
const Container = styled.div`
  min-width: 24px;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${flexGap(6)}

  &.badge {
    display: grid;
    grid-column-gap: 8px;
    grid-auto-flow: column;
  }

  .btn-text {
    font-weight: inherit;
    font-size: inherit;
    line-height: inherit;
  }
`;

export interface BaseButtonContentGeneralProps {
  btnStyle?: 'normal' | string;
  text?: string;
  loading?: boolean;
}

export interface IconButtonContentGeneralProps extends BaseButtonContentGeneralProps {
  btnStyle?: 'icon';
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  withLeftIcon?: boolean;
  withRightIcon?: boolean;
}

export interface BadgeButtonContentGeneralProps extends BaseButtonContentGeneralProps {
  btnStyle: 'badge';
  badgeLeft?: React.ReactNode;
  badgeRight?: React.ReactNode;
  withLeftBadge?: boolean;
  withRightBadge?: boolean;
}

export type ButtonContentGeneralProps =
  | BaseButtonContentGeneralProps
  | IconButtonContentGeneralProps
  | BadgeButtonContentGeneralProps;

export const ButtonContentGeneral = (props: ButtonContentGeneralProps) => {
  const { btnStyle, text, loading = false } = props;

  if (loading) {
    return (
      <Container className="button-content-general loading">
        <SpokeLoading color="currentColor" width={20} height={20} />
      </Container>
    );
  }

  switch (btnStyle) {
    case 'icon': {
      const {
        iconLeft,
        iconRight,
        withLeftIcon = false,
        withRightIcon = false,
      } = props as IconButtonContentGeneralProps;
      return (
        <Container className="button-content-general icon">
          {withLeftIcon && <Icon>{iconLeft}</Icon>}
          {text && <span className="btn-text">{text}</span>}
          {withRightIcon && <Icon>{iconRight}</Icon>}
        </Container>
      );
    }
    case 'badge': {
      const {
        badgeLeft,
        badgeRight,
        withLeftBadge = false,
        withRightBadge = false,
      } = props as BadgeButtonContentGeneralProps;
      return (
        <Container className="button-content-general badge">
          {withLeftBadge && <Badge>{badgeLeft}</Badge>}
          <span className="btn-text">{text}</span>
          {withRightBadge && <Badge>{badgeRight}</Badge>}
        </Container>
      );
    }
  }

  return (
    <Container className="button-content-general">
      <span className="btn-text">{text}</span>
    </Container>
  );
};
