import { css } from 'styled-components';

import { innerBorder } from '@doltech/utils/lib/css-style';
import { colorsV2 } from '../../colors-v2';

const colorRed = css`
  &.color-red {
    &.color-style-bold {
      ${innerBorder(`2px solid ${colorsV2.red100}`)}
      color: ${colorsV2.red100};

      &:hover:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.red60}`)}
      }

      &:active:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.red40}`)}
      }

      &.disabled {
        ${innerBorder(`2px solid ${colorsV2.gray10}`)}
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }

    &.color-style-matte {
      ${innerBorder(`2px solid ${colorsV2.red20}`)}
      color: ${colorsV2.red100};

      &:hover:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.red40}`)}
      }

      &:active:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.red60}`)}
      }

      &.disabled {
        ${innerBorder(`2px solid ${colorsV2.gray10}`)}
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }
  }
`;

const colorGreen = css`
  &.color-green {
    &.color-style-bold {
      ${innerBorder(`2px solid ${colorsV2.green100}`)}
      color: ${colorsV2.green100};

      &:hover:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.green60}`)}
      }

      &:active:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.green40}`)}
      }

      &.disabled {
        ${innerBorder(`2px solid ${colorsV2.gray10}`)}
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }

    &.color-style-matte {
      ${innerBorder(`2px solid ${colorsV2.green20}`)}
      color: ${colorsV2.green100};

      &:hover:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.green40}`)}
      }

      &:active:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.green60}`)}
      }

      &.disabled {
        ${innerBorder(`2px solid ${colorsV2.gray10}`)}
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }
  }
`;

const colorLight = css`
  &.color-light {
    &.color-style-bold {
      ${innerBorder(`2px solid ${colorsV2.white100}`)}
      color: ${colorsV2.gray140};

      &:hover:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.gray5}`)}
      }

      &:active:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.gray10}`)}
      }

      &.disabled {
        ${innerBorder(`2px solid ${colorsV2.white100}`)}
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }

    &.color-style-matte {
      ${innerBorder(`2px solid ${colorsV2.gray20}`)}
      color: ${colorsV2.gray140};

      &:hover:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.gray40}`)}
      }

      &:active:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.gray60}`)}
      }

      &.disabled {
        ${innerBorder(`2px solid ${colorsV2.gray5}`)}
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }
  }
`;

const colorNeutral = css`
  &.color-neutral {
    &.color-style-bold {
      ${innerBorder(`2px solid ${colorsV2.gray100}`)}
      color: ${colorsV2.gray180};

      &:hover:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.gray120}`)}
        color: ${colorsV2.gray140};
      }

      &:active:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.gray140}`)}
        color: ${colorsV2.gray140};
      }

      &.disabled {
        ${innerBorder(`2px solid ${colorsV2.gray10}`)}
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }

    &.color-style-matte {
      ${innerBorder(`2px solid ${colorsV2.gray20}`)}
      color: ${colorsV2.gray140};

      &:hover:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.gray40}`)}
      }

      &:active:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.gray60}`)}
      }

      &.disabled {
        ${innerBorder(`2px solid ${colorsV2.gray10}`)}
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }
  }
`;

const colorDark = css`
  &.color-dark {
    &.color-style-bold {
      ${innerBorder(`2px solid ${colorsV2.gray140}`)}
      color: ${colorsV2.gray140};

      &:hover:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.gray100}`)}
      }

      &:active:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.gray80}`)}
      }

      &.disabled {
        ${innerBorder(`2px solid ${colorsV2.gray10}`)}
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }

    &.color-style-matte {
      ${innerBorder(`2px solid ${colorsV2.gray20}`)}
      color: ${colorsV2.gray180};

      &:hover:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.gray40}`)}
      }

      &:active:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.gray80}`)}
      }

      &.disabled {
        ${innerBorder(`2px solid ${colorsV2.gray10}`)}
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }
  }
`;

const colorBlue = css`
  &.color-blue {
    &.color-style-bold {
      ${innerBorder(`2px solid ${colorsV2.blue100}`)}
      color: ${colorsV2.blue100};

      &:hover:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.blue60}`)}
      }

      &:active:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.blue40}`)}
      }

      &.disabled {
        ${innerBorder(`2px solid ${colorsV2.gray10}`)}
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }

    &.color-style-matte {
      ${innerBorder(`2px solid ${colorsV2.blue20}`)}
      color: ${colorsV2.blue100};

      &:hover:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.blue40}`)}
      }

      &:active:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.blue60}`)}
      }

      &.disabled {
        ${innerBorder(`2px solid ${colorsV2.gray10}`)}
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }
  }
`;

const colorPrimary = css`
  &.color-primary {
    &.color-style-bold {
      ${innerBorder(`2px solid ${colorsV2.primary100}`)}
      color: ${colorsV2.primary100};

      &:hover:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.primary60}`)}
      }

      &:active:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.primary40}`)}
      }

      &.disabled {
        ${innerBorder(`2px solid ${colorsV2.gray10}`)}
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }

    &.color-style-matte {
      ${innerBorder(`2px solid ${colorsV2.primary20}`)}
      color: ${colorsV2.primary100};

      &:hover:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.primary80}`)}
      }

      &:active:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.primary60}`)}
      }

      &.disabled {
        ${innerBorder(`2px solid ${colorsV2.gray10}`)}
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }
  }
`;
const colorPurple = css`
  &.color-purple {
    &.color-style-bold {
      ${innerBorder(`2px solid ${colorsV2.purple100}`)};
      color: ${colorsV2.purple100};

      &:hover:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.purple60}`)};
        color: ${colorsV2.purple60};
      }

      &:active:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.purple40}`)};
        color: ${colorsV2.purple40};
      }

      &.disabled {
        ${innerBorder(`2px solid ${colorsV2.gray10}`)}
        color: ${colorsV2.gray80};
        cursor: not-allowed;
      }
    }

    &.color-style-matte {
      ${innerBorder(`2px solid ${colorsV2.purple20}`)}
      color: ${colorsV2.purple100};

      &:hover:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.purple40}`)};
      }

      &:active:not(.loading):not(.disabled) {
        ${innerBorder(`2px solid ${colorsV2.purple60}`)};
      }

      &.disabled {
        ${innerBorder(`2px solid ${colorsV2.gray10}`)}
        cursor: not-allowed;
      }
    }
  }
`;

export const outlineStyles = css`
  &.outline:not(.transparent) {
    background-color: transparent;

    ${colorPrimary}
    ${colorBlue}
    ${colorDark}
    ${colorNeutral}
    ${colorLight}
    ${colorGreen}
    ${colorRed}
    ${colorPurple}
  }
`;
